/*
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;
*/

$primary: #59984d;
$dark: #132746;
$secondary: #243e24;

$enable-responsive-font-sizes: true;

$spacer: 2rem;

// Body
$body-bg: #fff;

// Typography
$enable-responsive-font-sizes: true;
$font-size-base: 1rem;
$link-color: #333;

// Btn
$btn-border-radius: 20px;
$btn-padding-x: 2rem;

$navbar-nav-link-padding-x: 1rem;