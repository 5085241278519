* { outline:none; }

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none;
}

html, body{
    color: #707070;
    max-width: 100%;
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
}

h1,h2,h3,h4,h5,h6{
    text-transform: uppercase;
}

.text-lg{
    font-size: 1.2rem;
}

p{
    opacity: 0.8;
}

/* Btn */
.btn{
    text-transform: uppercase;
}
/* End Btn */

/* Webdoor */
.webdoor{    
    background-color: #666;
    z-index: 0;
    position: relative;
    margin-top: 90px;
}
@include media-breakpoint-down(xs) { 
    .webdoor{
        height: auto;
        margin-top: 80px;
    }
}
/* End Webdoor */

/* Header */
header{
    position: fixed;
    width: 100%;
    z-index: 2;
}
header .bg-rounded{
    position: absolute;
    background-color: $secondary;
    width: 170%;
    margin-left: -35%;
    border-radius: 100%;
    top: -60px;
    height: 170px;
    display: none;
}
header .bg-rounded-secondary{
    position: absolute;
    background-color: $primary;
    width: 130%;
    margin-left: -70%;
    border-radius: 100%;
    top: -60px;
    height: 170px;
    display: none;
}
header .navbar-brand img{
    width: 155px;
}
@include media-breakpoint-down(xs) { 
    header .navbar-brand img{
        width: 115px;
    }
}
header .nav-link{
    text-transform: uppercase;
    font-size: 0.85rem;
    color: #fff !important;
}
/* End Header */

.navbar-collapse.collapsing,
.navbar-collapse.collapse.show{
    left: -15px;
    position: relative;
    background: $secondary;
    padding-left: 25px;
    padding-top: 25px;    
}

/* Card */
.card.video{
    position: relative;
}
.card.video > div{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 100px;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px;
    color: #fff;
}
@include media-breakpoint-down(xs) {
    .card.video{
        height: 230px !important;
    }       
}
/* End Card */

/* Swiper */
.swiper-button-next{
    right: -40px;
}
.swiper-button-prev{
    left: -40px;
}
.swiper-pagination-bullet-active{
    background-color: $dark;
}
/* End Swiper */

/* Numbers */
.block-numbers h2{
    font-size: 2.6rem;
    font-weight: bold;
}
.block-numbers h2, 
.block-numbers small{
    font-weight: bold;
}
.block-numbers p{
    font-size: 1rem;
}
@include media-breakpoint-down(xs) { 
    .block-numbers{
        background-color: $secondary;
    }
    .block-numbers .bg-rounded,
    .block-numbers .bg-rounded-secondary{
        display: none;
    }
}
/* End Numbers */

/* Numbers */
.block-funds .swiper-slide h2{
    font-size: 3rem;
    font-weight: bold;
}
.block-funds .swiper-slide a{
    font-size: 1.2rem;
}
/* End Numbers */

/* Profitability */
.block-profitability table thead tr th{
    border: 0;
    border-top: 2px solid $dark;
    background-color: transparent;
    font-size: 0.8rem;
    color: $dark;        
}
.block-profitability table tbody tr td{
    font-size: 0.8rem;
    border: 0;
}
.block-profitability table tbody tr.tr-chart{
    width: 100%;
    display: none;
}
.block-profitability .table tbody+tbody{
    border: 0;
}
/* End Profitability */

/* Tabs */
.block-tabs .nav-tabs .nav-item .nav-link{
    background-color: $secondary;
    color: #fff;
    border-radius: 0;
    border: 0;
    border-top: 1px solid $secondary;
    text-transform: uppercase;
}
.block-tabs .nav-tabs .nav-item .nav-link.active{
    background-color: $light;
    color: $dark;
}
.block-tabs h2{
    font-size: 1rem;
    color: $dark;
    text-transform: none;
    font-weight: bold;
}
.block-tabs p{
    font-size: 0.9rem;
}
/* End Tabs */

/* Block About us */
.block-about-us{
    position: relative;
    overflow-y: hidden;
    margin-top: -7px;    
    background-image: url(../../imgs/bg_fundo_branco.png);    
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -140px;
}
.block-about-us .detail{
    position: absolute;
    top: 0;
    right: -130px;    
}
.block-about-us .bg-rounded{
    position: absolute;
    background-color: #fff;
    width: 135%;
    margin-left: -25%;
    border-radius: 100%;
    top: 0px;
    height: 200px;
    display: none;
}
.block-about-us .bg-rounded-secondary{
    position: absolute;
    background-color: $primary;
    width: 130%;
    margin-left: 30%;
    border-radius: 100%;
    top: -10px;
    height: 20%;
    display: none;
}
@include media-breakpoint-down(xs) { 
    .block-about-us .bg-rounded,
    .block-about-us .bg-rounded-secondary,
    .block-about-us .detail{
        display: none;
    }
}
/* End Block About us */

/* Block Numbers */
.block-numbers{
    position: relative;
    overflow-y: hidden;
    margin-top: -60px;
}
.block-numbers .bg-rounded{
    position: absolute;
    background-color: $dark;
    width: 150%;
    margin-left: -25%;
    border-radius: 100%;
    top: 0px;
    height: 100%;
}
.block-numbers .bg-rounded-secondary{
    position: absolute;
    background-color: $primary;
    width: 130%;
    margin-left: -100%;
    border-radius: 100%;
    top: -10px;
    height: 20%;
}
/* End Block Numbers */

/* Block Videos */
.block-videos{
    position: relative;
    margin-top: -70px;   
}
.block-videos .bg-rounded{
    position: absolute;
    background-color: #fff;
    width: 135%;
    margin-left: -25%;
    border-radius: 100%;
    top: 0px;
    height: 150px;
}
.block-videos .bg-rounded-secondary{
    position: absolute;
    background-color: $primary;
    width: 130%;
    margin-left: 60%;
    border-radius: 100%;
    top: -10px;
    height: 20%;
}
/* End Block Videos */

/* Block ESG */
.block-esg{
    position: relative;    
    margin-top: -60px;    
}
.block-esg .bg-rounded{
    position: absolute;
    background-color: $secondary;
    width: 150%;
    margin-left: -25%;
    border-radius: 100%;
    top: -2px;
    height: 150px;
}
.block-esg .bg-rounded-secondary{
    position: absolute;
    background-color: $success;
    width: 120%;
    margin-left: -80%;
    border-radius: 100%;
    top: -40px;
    height: 20%;
}
@include media-breakpoint-down(xs) { 
    .block-esg .bg-rounded,
    .block-esg .bg-rounded-secondary{
        display: none;
    }
}
/* End Block ESG */

/* Footer */
footer{
    position: relative;       
}
footer .bg-rounded{
    position: absolute;
    background-color: $dark;
    width: 150%;
    margin-left: -25%;
    border-radius: 100%;
    top: -15px;
    height: 150px;
}
footer .bg-rounded-secondary{
    position: absolute;
    background-color: $success;
    width: 120%;
    margin-left: 60%;
    border-radius: 100%;
    top: -50px;
    height: 13%;
}
footer p{
    font-size: 0.8rem;
    opacity: 0.8;
}
footer ul li a{
    font-size: 1rem;
}
footer input[type=text],
footer input[type=email],
footer textarea{
    background: none !important;
    border: 0 !important;
    border-bottom: 1px solid #fff !important;
    border-radius: 0 !important;
}
@include media-breakpoint-down(xs) { 
    footer .bg-rounded,
    footer .bg-rounded-secondary{
        display: none;
    }
}
/* End Footer */

/* Fund Single */
.fund-features .table-responsive{
    border-radius: 20px;
    overflow: hidden;
}
.fund-features .table-responsive .table-striped tbody tr:nth-of-type(odd){
    background-color: #eff6ee;
}
/* End Fund Single */

/* Page Blog */
.page-blog{
    background-image: url(../../imgs/bg_fundo_branco.png);    
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -140px;    
}
.page-blog .date{
    display: inline-block;
    width: 250px;    
}
.page-blog table tr td{
    border-top: 0;
    padding-top: 7px;
    padding-bottom: 7px;       
}
.page-blog table tr td a{
    font-size: 1.4rem;
    color: #666;
    letter-spacing: 2px; 
    border-bottom: 1px solid #ccc;
    width: 100%;
    display: block;
    padding-bottom: 10px;    
}
/* End Page Blog */

/* Single Fund */
section.single-fund{
    background-image: url(../../imgs/bg_fundo_branco.png);    
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -140px;    
}
/* End Single Fund */

/* Contact Modal */
#contact-modal input[type=text],
#contact-modal input[type=email]{
    width: 100%;
    border: 0;
    border-bottom: 1px solid $dark;
    padding-bottom: 5px;
    background-color: transparent;
}
#contact-modal .modal-content{
    border-radius: 30px;
    overflow: hidden;
    background: transparent;    
}
#contact-modal .modal-header{
    background-color: $dark;
    position: relative;
    text-align: center;
}
#contact-modal .modal-header .close{
    position: absolute;
    top: 5px;
    right: 15px;
    opacity: 1;    
}
#contact-modal .modal-header .close span{
    color: #fff;
}
#contact-modal .modal-header .modal-title{
    width: 100%;
    color: #fff;
}
#contact-modal .modal-footer{
    border: 0;
}
#contact-modal .modal-footer{
    background-color: #fff;
}   
#contact-modal .modal-body{
    background-image: url(../../imgs/bg_fundo_branco.png);    
    background-size: 100%;
    background-repeat: no-repeat;
}
/* End Contact Modal */


.home .block-funds, .single-macro, .page-funds, .page-profitability{
    background-image: url(../../imgs/bg_fundo_branco.png);    
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -140px;    
}

.page-funds .detail,
.page-profitability .detail,
.page-blog .detail,
.single-macro .detail,
.single-funds .detail{
    position: absolute;
    top: 90px;
    right: -130px;    
}

.page-funds .detail{
    top: 90px;
}
@include media-breakpoint-down(xs) { 
    .page-funds .detail{
        top: 65px;
    }
}

.single-macro .detail{
    top: -1px;
}

section.single-fund{
    background-position-y: -50px; 
}

.single-macro {
    position: relative;
}

@include media-breakpoint-up(lg) { 
    .modal-lg, .modal-xl{
        max-width: 80%;
    }
    .table-responsive{
        overflow-x: hidden;
    }
}

@include media-breakpoint-down(xs) { 
    .tr-chart.d-table-row section{
        width: 86vw;
    }
    .tr-chart.d-table-row .nav-link{
        display: block;
        padding: .5rem 0.5rem;
        font-size: 0.8rem;  
    }  
}